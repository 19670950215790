<template>
    <div>
      <!-- Start Header Area -->
      <Header>
        <img slot="logo" :src="logo" />
      </Header>
      <!-- End Header Area -->
  
      <!-- Start Breadcrump Area  -->
      <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center rn-page-title pt--100">
                <h2 class="heading-title theme-gradient">
                  Major Project: Learning Experience Design Proposal
                </h2>
                <p>Module 5 Assignment</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Breadcrump Area  -->
  
      <!-- Start Portfolio Details Area  -->
      <div class="rn-portfolio-details ptb--120 bg_color--1">
        <v-container>
          <v-row>
            <v-col>
              <div class="portfolio-details">
                <div class="row row--35 mb--50">
                  <div class="col-lg-12">
                    <div class="inner">
                      <div class="section-title">
                        <h2 class="heading-title">Part 2: Trial & Assessment</h2>
                        <div
                          class="description mt--30"
                          style="text-align: justify !important"
                        >
                        <h4>Assessment Strategy</h4>
                        <p>This learning experience will use both formative and summative assessment approaches to evaluate students' acquired skills, knowledge, emotional engagement, and the potential transfer of knowledge between domains. The assessments are designed to provide ongoing feedback, which is critical to fostering student learning and ensuring mastery of the course objectives.</p>
                        <hr>
                        <h4>Formative Assessment</h4>
                        <p>Formative assessments will take place throughout the 15-week course. These assessments are intended to guide students' progress and provide timely feedback to adjust learning strategies and improve comprehension. Examples include:</p>
                        <ul>
                            <li><strong>Code Reviews:</strong> Regular peer and instructor reviews of students’ code to provide feedback on their implementation of best practices, debugging, and adherence to requirements.</li>
                            <li><strong>Lab Activities:</strong> Weekly hands-on labs where students develop parts of a full-stack application. Labs will be followed by immediate feedback on both the process and outcome, ensuring continuous learning.</li>
                            <li><strong>Reflection Journals:</strong> Students will maintain journals reflecting on their learning experiences, which will be reviewed periodically to gauge their emotional engagement and self-assessment of their knowledge.</li>
                            <li><strong>Quizzes:</strong> Short, low-stakes quizzes to check comprehension of key concepts (e.g., API integration, deployment processes) and adjust instruction as necessary.</li>
                        </ul>
<hr>
                        <h4>Summative Assessment</h4>
                        <p>Summative assessments will evaluate students’ overall mastery of the full-stack development process and their ability to apply what they have learned in real-world scenarios. These assessments include:</p>
                        <ul>
                            <li><strong>Capstone Project:</strong> By the end of the course, students will be required to build and deploy a full-stack web application that meets specified functional and performance requirements. This project will be assessed on its technical accuracy, scalability, user interface design, and adherence to security standards.</li>
                            <li><strong>Final Presentation:</strong> Students will present their capstone projects to their peers, simulating a real-world client meeting. This will assess their communication skills and ability to explain technical concepts to non-technical stakeholders.</li>
                            <li><strong>Comprehensive Written Exam:</strong> A final exam will cover the theoretical aspects of full-stack development, including database management, API development, and security protocols. The focus will be on applying concepts rather than rote memorization.</li>
                        </ul>
<hr>
                        <h4>Feedback and Reflection</h4>
                        <p>In alignment with Wiliam’s emphasis on the role of feedback in learning, feedback will be an integral part of both formative and summative assessments. The feedback will focus on specific, actionable insights to help students bridge the gap between their current and desired performance levels.</p>
                        <hr>
                        <h4>Transfer of Knowledge</h4>
                        <p>Assessments will also evaluate the potential for knowledge transfer, ensuring students can apply full-stack development skills to different domains. For instance, students will be asked to extend their projects to accommodate new features or integrate third-party APIs not covered in the course.</p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Portfolio Details Area  -->
  
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from "../../components/header/HeaderThree";
  import Footer from "../../components/footer/FooterTwo";
  import feather from "feather-icons";
  
  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/portfolio/portfolio-big-03.jpg"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
        ],
        socialList: [
          {
            url: "https://www.facebook.com/",
            icon: "facebook",
          },
          {
            url: "https://www.linkedin.com/",
            icon: "linkedin",
          },
          {
            url: "https://instagram.com/",
            icon: "instagram",
          },
          {
            url: "https://twitter.com/",
            icon: "twitter",
          },
        ],
        moreProjects: [
          {
            src: require("../../assets/images/portfolio/related-image-01.jpg"),
            title: "Digital Analysis",
            categorie: "Technique",
          },
          {
            src: require("../../assets/images/portfolio/related-image-02.jpg"),
            title: "Plan Management",
            categorie: "Planning",
          },
        ],
        index: null,
      };
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
  </script>
  